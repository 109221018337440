interface Props {
  cookies: Map<string, string>;
  setCookie: (name: string, value: string, days: number) => void;
  removeCookie: (name: string) => void;
}

const useCookies = (): Props => {
  const cookies = new Map(
    document.cookie.split(';').map((c) => c.trim().split('=')) as [
      string,
      string
    ][]
  );

  const setCookie = (
    cookieName: string,
    cookieValue: string,
    days: number
  ): void => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${cookieName}=${cookieValue}; expires=${date.toUTCString()}; path=/`;
  };

  const removeCookie = (cookieName: string): void => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  return { cookies, setCookie, removeCookie };
};

export default useCookies;
