import { useContext } from 'react';

import WeKnowProfile from '../../assets/img/WeKnow_Profile.png';
import WeKnowTeam from '../../assets/img/WeKnow_Team.png';
import { AppContext, AppContextValueType } from '../../store/app-context';
import { AvatarModeTypes, Props } from './WeKnowAvatar.types';

function WeKnowAvatar({
  mode,
  size,
  src = '',
  margin = 'mx-auto',
}: Props): JSX.Element {
  const { appData } = useContext<AppContextValueType>(AppContext);
  let defaultSrc;
  let currentAvatar = '';
  switch (mode) {
    case AvatarModeTypes.MY_PROFILE:
      defaultSrc = WeKnowProfile;
      currentAvatar = appData.profilePicture;
      break;
    case AvatarModeTypes.ANY_PROFILE:
      defaultSrc = WeKnowProfile;
      break;
    case AvatarModeTypes.TEAM:
      defaultSrc = WeKnowTeam;
      break;
    default:
      defaultSrc = WeKnowProfile;
  }
  return (
    <img
      className={`rounded-full ${margin} border border-white`}
      style={{ width: `${size}px` }}
      src={src || currentAvatar || defaultSrc}
      alt={mode}
    />
  );
}

export default WeKnowAvatar;
