import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useContext, useMemo } from 'react';

import { useMsal } from '@azure/msal-react';

import { AuthContext } from '../index';

const controller = new AbortController();
interface UseAxiosReturn {
  doRequest: (
    path: string,
    options: AxiosRequestConfig,
    onUploadProgress?: null | ((progress: number) => void)
  ) => Promise<AxiosResponse>;
}

const useApi = (): UseAxiosReturn => {
  const { instance } = useMsal();
  const [account] = useMemo(() => instance.getAllAccounts(), [instance]);
  const { config } = useContext(AuthContext);

  const getAccessToken = useCallback(() => {
    const request = {
      account,
      scopes: [`${config.REACT_APP_API_CLIENT_ID}/.default`],
    };
    return (
      instance
        .acquireTokenSilent(request)
        .then((accountInformation) => accountInformation.accessToken)
        // if acquireTokenSilent fails (can happen on Safari, especially on iOS with disallowing cross site tracking) then we use this:
        .catch(() => instance.acquireTokenRedirect(request))
    );
  }, [instance]);

  const doRequest = useCallback(
    async (
      path: string,
      options: AxiosRequestConfig,
      onUploadProgress: null | ((progress: number) => void) = null
    ) => {
      // require fresh access token
      const accessToken = await getAccessToken();

      const generalOptions = {
        url: `${config.REACT_APP_API_BASE_URL}${path}`,
        signal: controller.signal,
        headers: {},
      };
      const fullOptions = Object.assign(generalOptions, options);

      if (onUploadProgress !== null) {
        // attach callback for onUploadProgress
        fullOptions.onUploadProgress = (progressEvent) => {
          const progress =
            progressEvent && progressEvent.loaded && progressEvent.total
              ? (progressEvent.loaded / progressEvent.total) * 50
              : 0;
          onUploadProgress(progress);
        };
      }

      // append general headers
      fullOptions.headers.authorization = `Bearer ${accessToken}`;

      // return axios call
      return axios(fullOptions);
    },
    [instance, account, config]
  );

  return {
    doRequest,
  };
};

export default useApi;
