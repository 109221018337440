import { useContext, useState } from 'react';
import { AppContext, AppContextValueType } from '../../store/app-context';

function AppSwitcherUsed(): JSX.Element {
  const { appData } = useContext<AppContextValueType>(AppContext);
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = (): void => setIsOpen(false);

  if (!appData.hasConsentTerms || !isOpen) {
    return <div />;
  }

  return (
    <div className="absolute top-0 left-0 flex w-full h-full">
      <div
        className="absolute top-0 bottom-0 left-0 right-0 bg-black cursor-auto bg-opacity-80"
        onClick={closePopup}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            closePopup();
          }
        }}
        tabIndex={0}
        role="button"
        aria-label="Close"
      />
      <div
        className={`
                absolute left-3 right-3 top-[80px] flex items-center justify-center
                before:absolute before:-top-4 before:left-2
                before:border-transparent before:border-solid before:border-8 before:border-b-weknow-default
            `}>
        <div className="relative z-10 w-full p-4 border-2 rounded shadow-md bg-weknow-dark border-weknow-default">
          <h4 className="mb-4 font-semibold text-white text-md">
            Switch applications
          </h4>
          <p className="inline-block w-full text-sm text-left text-white">
            Click the app switcher icon to change applications.
          </p>{' '}
          <p className="inline-block w-full mt-2 text-sm text-left text-white">
            Only applications you are authorized to use are shown.
          </p>
          <button
            type="button"
            onClick={closePopup}
            className="px-4 py-1 mt-4 text-white transition rounded bg-weknow-darker hover:bg-opacity-70">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppSwitcherUsed;
