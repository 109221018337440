export interface Props {
  mode: AvatarModes;
  size: number;
  src?: string;
  margin?: string;
}

export enum AvatarModeTypes {
  MY_PROFILE = 'MY_PROFILE',
  ANY_PROFILE = 'ANY_PROFILE',
  TEAM = 'TEAM',
}

export type AvatarModes =
  | AvatarModeTypes.MY_PROFILE
  | AvatarModeTypes.ANY_PROFILE
  | AvatarModeTypes.TEAM;
