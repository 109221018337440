import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { AppContext, AppContextValueType } from '../store/app-context';
import Props from './ProtectedTeam.types';
import { Navigate } from '../helper/WCINavigate';

function ProtectedTeam({ target }: Props): JSX.Element {
  const { appData } = useContext<AppContextValueType>(AppContext);

  const isTeamMember = !!appData.teamId;

  switch (target) {
    case 'my-team':
      if (!isTeamMember) {
        // user is not part of a team - redirect to join team page
        return <Navigate to="/join-team" />;
      }
      break;
    case 'join-team':
      // join team views
      if (isTeamMember) {
        // team members can not join teams - redirect to team page
        return <Navigate to="/team" />;
      }
      break;
    default:
  }
  return <Outlet />;
}

export default ProtectedTeam;
