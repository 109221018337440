import './index.scss';

import axios from 'axios';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

import {
  AccountInfo,
  Configuration,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  useMsal,
} from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import * as serviceWorker from './serviceWorker';
import AppContextProvider from './store/app-context';

const queryClient = new QueryClient();

function AuthStatusScreen({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="auth__screen">{children}</div>;
}

/* eslint-disable */
const AuthError = ({
  error,
}: React.PropsWithChildren<MsalAuthenticationResult>): JSX.Element => {
  if (error) {
    console.error(error);
  }
  return (
    <AuthStatusScreen>
      <div className="auth__error-title">An Error Occurred</div>
      {error && <div className="auth__error-message">{error.toString()}</div>}
    </AuthStatusScreen>
  );
};

const AuthLoading = (): JSX.Element => (
  <AuthStatusScreen>
    <div></div>
  </AuthStatusScreen>
);
/* eslint-enable */

export type IAuthContext = {
  account: AccountInfo;
  config: Record<string, unknown>;
};

export const AuthContext = React.createContext<IAuthContext>({
  config: {},
  account: {
    name: '',
    username: '',
    tenantId: '',
    environment: '',
    homeAccountId: '',
    idTokenClaims: {},
    localAccountId: '',
  },
});

(async () => {
  const res = await axios.get('/config.json');
  const config = res.data;

  const authConfiguration: Configuration = {
    auth: {
      redirectUri: '/',
      clientId: config.REACT_APP_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${config.REACT_APP_TENANT_ID}`,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  };

  const authInstance =
    await PublicClientApplication.createPublicClientApplication(
      authConfiguration
    );

  /* eslint-disable */
  const AuthProvider = ({
    children,
  }: {
    children: React.ReactNode;
  }): JSX.Element => {
    const auth = useMsal();
    const [account] = auth.instance.getAllAccounts();
    const rumInitiated = React.useRef(false);

    // effect for datadog
    React.useEffect(() => {
      if (!rumInitiated.current && account) {
        rumInitiated.current = true;

        const DATADOG_SITE = 'datadoghq.eu';
        const DATADOG_SERVICE = 'weknow';
        const DATADOG_PROXY_HOST = 'https://datadog-proxy.app.wartsila.com';
        const DATADOG_APP_ID = 'bdd0c66c-1dd1-4fad-9e39-1c89e6c769a3';
        const DATADOG_CLIENT_TOKEN = 'pubb75fa100dbed05a6977fe3490593e008';

        datadogRum.init({
          sampleRate: 100,
          site: DATADOG_SITE,
          trackInteractions: true,
          service: DATADOG_SERVICE,
          applicationId: DATADOG_APP_ID,
          proxyUrl: DATADOG_PROXY_HOST,
          clientToken: DATADOG_CLIENT_TOKEN,
          env: config.REACT_APP_ENVIRONMENT,
          version: config.REACT_APP_VERSION,
          allowedTracingOrigins: [config.REACT_APP_API_BASE_URL],
        });

        datadogRum.setUser({
          name: account.name,
          email: account.username,
        });
      }
    }, [account]);

    const providerValue = React.useMemo(() => {
      return {
        account,
        config,
      };
    }, [account, config]);

    return (
      <AuthContext.Provider value={providerValue}>
        {children}
      </AuthContext.Provider>
    );
  };

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <MsalProvider instance={authInstance}>
        <MsalAuthenticationTemplate
          errorComponent={AuthError}
          loadingComponent={AuthLoading}
          interactionType={InteractionType.Redirect}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthProvider>
                <AppContextProvider>
                  <App />
                </AppContextProvider>
              </AuthProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </React.StrictMode>
  );
  serviceWorker.unregister();
})();
