import { Outlet } from 'react-router-dom';

import Props from './ProtectedAdmin.types';
import { Navigate } from '../helper/WCINavigate';

function ProtectedAdmin({ isAdmin = false }: Props): JSX.Element {
  if (!isAdmin) {
    // user is not admin
    return <Navigate to="/" />;
  }
  return <Outlet />;
}

export default ProtectedAdmin;
