import {
  LinkProps,
  NavLinkProps,
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
  useParams,
} from 'react-router-dom';

/**
 * Custom React Router Link component that automatically adds the `prj_label` prefix
 */
export function Link({ to, ...rest }: LinkProps): JSX.Element {
  const { prj_label } = useParams();

  // Return the original Link component with the prefixed path
  return (
    <ReactRouterLink to={prj_label ? `/${prj_label}${to}` : to} {...rest} />
  );
}

/**
 * Custom React Router NavLink component that automatically adds the `prj_label` prefix
 */
export function NavLink({ to, ...rest }: NavLinkProps): JSX.Element {
  const { prj_label } = useParams();

  // Return the original Link component with the prefixed path
  return (
    <ReactRouterNavLink to={prj_label ? `/${prj_label}${to}` : to} {...rest} />
  );
}
